import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import {
  About,
  Layout,
  Hero,
  History,
  FocusTabs,
  LatestNews
} from '../components';

const IndexPage = ({ data }) => {
  const { data: homepage } = data.prismicHomepage;
  const newsItems = data.allPrismicNewsItem.edges;
  return (
    <Layout>
      <Hero data={homepage} />
      <FocusTabs data={homepage} />
      <Main>
        <LatestNews data={newsItems} />
        <About data={homepage} />
        <History data={homepage} />
      </Main>
    </Layout>
  );
};

export default IndexPage;

const Main = styled.main`
  max-width: 100%;
  overflow-x: hidden;
`;

IndexPage.propTypes = {
  data: PropTypes.shape({
    prismicMainNavigation: PropTypes.shape({
      data: PropTypes.object.isRequired
    }),
    prismicHomepage: PropTypes.shape({
      data: PropTypes.object.isRequired
    })
  }).isRequired
};

export const IndexQuery = graphql`
  query IndexQuery {
    prismicHomepage {
      data {
        hero_caption {
          text
        }
        hero_image {
          url
          alt
        }
        focus_tabs {
          focus_tab_title {
            text
          }
          focus_tab_description {
            text
          }
          focus_tab_link {
            url
          }
        }
        about_title {
          text
        }
        about {
          html
        }
        history_title {
          text
        }
        history {
          html
        }
        history_image {
          url
        }
      }
    }
    allPrismicNewsItem(limit: 3, sort: { fields: [data___date], order: DESC }) {
      edges {
        node {
          id
          data {
            title {
              text
            }
            content {
              text
            }
            featured_image {
              url
            }
          }
          uid
        }
      }
    }
  }
`;
